import axios from 'axios';

const DEFAULT_BASE_URL = 'https://zavodprod-jobsoutputbucket402c3821-11q6hk8nt0ft8.s3.amazonaws.com/data/';
const ORGANIZATION_URL = '/organizations.json';
const STATS_URL = '/stats.json';

const http = axios.create({
    baseURL: process.env.BASE_URL || DEFAULT_BASE_URL
});

async function getOrganizationsData() {
    return await _getData(ORGANIZATION_URL);
}

async function getStatsData() {
    return await _getData(STATS_URL);
}

async function _getData(url) {
    const { data } = await http.get(url);

    return data;
}

export {
    getOrganizationsData,
    getStatsData
}
