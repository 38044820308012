const CTRL_KEY_CODE = 17;

const onMapKeydown = (mapInstance) => {
    return (event) => {
        if (event.keyCode === CTRL_KEY_CODE) {
            mapInstance.behaviors.enable('scrollZoom');
        }
    };
};

const onMapKeyup = (mapInstance) => {
    return (event) => {
        if (event.keyCode === CTRL_KEY_CODE) {
            mapInstance.behaviors.disable('scrollZoom');
        }
    };
};

const onPointMouseEnter = (objectManager) => {
    return (event) => {
        const objectId = event.get('objectId');

        objectManager.objects.balloon.open(objectId);
    }
};

const onPointMouseLeave = (objectManager) => {
    return () => {
        objectManager.objects.balloon.close();
    }
};

export const initializeZoomBehaviour = (mapInstance) => {
    mapInstance.behaviors.disable('scrollZoom');

    document.addEventListener('keydown', onMapKeydown(mapInstance));
    document.addEventListener('keyup', onMapKeyup(mapInstance));
};

export const initializePointsBehaviour = (objectManager) => {
    objectManager.objects.events.add('mouseenter', onPointMouseEnter(objectManager));
    objectManager.objects.balloon.events.add('mouseleave', onPointMouseLeave(objectManager));
};
