export const getZoomControl = () => {
    return new ymaps.control.ZoomControl({
        options: {
            position: {
                right: 10,
                top: 50,
            },
        }
    });
};
