const DEFAULT_SUPPORTERS_COUNT = 0;

export default function (organizationsList, organizationsStats) {
    return organizationsList.reduce((result, organization) => {
        const { supporters = DEFAULT_SUPPORTERS_COUNT, byQuestion = {} } = organizationsStats.find(({ organizationId }) => organizationId === organization.id) || {};

        if (supporters === 0) {
            return result;
        }

        const {
            QuestionStrikeIdea: questionStrikeIdea = DEFAULT_SUPPORTERS_COUNT,
            QuestionItalianStrike: questionItalianStrike = DEFAULT_SUPPORTERS_COUNT,
            QuestionExitUnion: questionExitUnion = DEFAULT_SUPPORTERS_COUNT
        } = byQuestion;

        return [...result, { ...organization, supporters, questionStrikeIdea, questionItalianStrike, questionExitUnion }]
    }, []);
};
