import { getStatsData, getOrganizationsData } from './lib/api';
import mapSupportersData from './lib/mapper';
import { getSupportersMapData } from './lib/supporters';
import { getMap, getObjectManager } from './lib/map';
import { getZoomControl } from './lib/controls';
import { initializeZoomBehaviour, initializePointsBehaviour } from './lib/behaviours';
import * as botLinksWrapper from './lib/bot-links-wrapper';

function updateSupportersCounter({ total: { supporters, supportingOrganizations } }) {
    const supportersHtml = `
        <div class="bot-info-counter-count">
            ${supporters}
        </div>
        <p class="bot-info-counter-subtitle">
            рабочих из
        </p>
        <p class="bot-info-counter-count">
            ${supportingOrganizations}
        </p>
        <p class="bot-info-counter-subtitle">
            предприятий<br><br>
            поддерживают забастовку
        </p>
    `;

    document.querySelector('#botInfoCounterSupporters').innerHTML = supportersHtml;
}

function init(mapData) {
    const mapInstance = getMap();
    const objectManager = getObjectManager();

    initializeZoomBehaviour(mapInstance);
    initializePointsBehaviour(objectManager);

    mapInstance.geoObjects.add(objectManager);
    mapInstance.controls.add(getZoomControl());
    objectManager.add(mapData);
}

document.addEventListener('DOMContentLoaded', () => {
    botLinksWrapper.init();

    Promise.all([getOrganizationsData(), getStatsData()]).then(([organizationsData, statsData]) => {
        const supportersData = mapSupportersData(organizationsData, statsData.byOrganization);
        const mapData = getSupportersMapData(supportersData);

        updateSupportersCounter(statsData);

        ymaps.ready(() => {
            init(mapData);
        });
    });
});
