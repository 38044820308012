import { DEFAULT_ZOOM, DEFAULT_CENTER, MAX_ZOOM } from './constants';

const getClusterBalloonItemContentLayout = () => {
  return ymaps.templateLayoutFactory.createClass('{{ properties.balloonContentBody|raw }}');
};

export const getMap = () => {
    return new ymaps.Map('map-container', {
        center: DEFAULT_CENTER,
        zoom: DEFAULT_ZOOM,
        controls: []
    }, {
        maxZoom: MAX_ZOOM
    });
};

export const getObjectManager = () => {
    return new ymaps.ObjectManager(
        {
            clusterize: true,
            clusterIconLayout: 'default#pieChart',
            clusterIconPieChartRadius: 25,
            clusterIconPieChartCoreRadius: 10,
            clusterIconPieChartStrokeWidth: 3,
            zoomMargin: 150,
            hideIconOnBalloonOpen: false,
            clusterBalloonItemContentLayout: getClusterBalloonItemContentLayout()
        },
        {
            showInAlphabeticalOrder: true,
        }
    );
};
