const getBalloonContentHeader = (organizationName) => {
  return `
    <span class="map-balloon-content-header">
        <p>${organizationName}</p>
    </span>
  `;
};

const getBalloonContentBody = ({questionExitUnion, questionItalianStrike, questionStrikeIdea}) => {
    return `
        <div class="map-balloon-content-body">
            <p>Поддерживают идею забастовки: ${questionStrikeIdea}</p>
            <p>Поддерживают, или готовы поддержать итальянскую забастовку: ${questionItalianStrike}</p>
            <p>Вышли из провластного профсоюза: ${questionExitUnion}</p>
        </div>
    `;
};

const buildFeatures = (supporters) => {
    return supporters.map((supporter) => ({
        type: 'Feature',
        id: supporter.id,
        geometry: {
            type: 'Point',
            coordinates: supporter.coordinates
        },
        properties: {
            hasBalloon: true,
            balloonContentHeader: getBalloonContentHeader(supporter.name),
            clusterCaption: supporter.name,
            balloonContentBody: getBalloonContentBody(supporter),
        },
        options: {
            hideIconOnBalloonOpen: false
        }
    }));
};

export const getSupportersMapData = (supporters) => {
    return {
        type: 'FeatureCollection',
        features: buildFeatures(supporters)
    };
};


