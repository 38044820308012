import qs from 'qs';

const BOT_LINKS = {
    telegram: 'https://t.me/zavod_by_bot',
    viber: 'https://bit.ly/zavod_by_bot',
};

const BOT_LINK_CLASSES = {
    telegram: 'telegram-link',
    viber: 'viber-link',
}

export function init() {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (!params.utm_source) {
        return;
    }

    document.addEventListener('click', (event) => {
        const { target } = event;

        if (target.tagName !== 'A') {
            return;
        }

        const classes = [...target.classList];

        if (classes.includes(BOT_LINK_CLASSES.viber)) {
            event.preventDefault();
            event.stopPropagation();

            window.open(
                BOT_LINKS.viber + '?' + qs.stringify({ platform: 'viber', utm_source: params.utm_source }),
                '_blank',
            );
        } else if (classes.includes(BOT_LINK_CLASSES.telegram)) {
            event.preventDefault();
            event.stopPropagation();

            window.open(
                BOT_LINKS.telegram + '?' + qs.stringify({ start: qs.stringify({ utm_source: params.utm_source }) }),
                '_blank',
            );
        }
    });
}
